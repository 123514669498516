import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { paymentStatuses } from "../../models/OrderModel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

interface Props {
  paymentStatus: string;
  fromDate: Date;
  toDate: Date;
  searchKeyword: string;
  resellerId?: number;
  resellers?: {
    subCompanyName: string;
    id: number;
  }[];
  setPaymentStatus(value: string): void;
  setFromDate(date: Date): void;
  setToDate(date: Date): void;
  setSearchKeyword(value: string): void;
  setResellerId(value: number): void;
}

export const FilterPanel: React.FC<Props> = (props) => {
  return (
    <>
      <Grid item xs={12} lg={4}>
        <TextField
          value={props.searchKeyword}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "10px",
              height: "45px",
            },
          }}
          onChange={(e) => props.setSearchKeyword(e.target.value)}
          placeholder="Search by Product Name.."
        />
      </Grid>
      <Grid item xs={12} lg={2} mt={{ xs: 2, lg: 0 }}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(props.fromDate)}
              onChange={(date) => {
                props.setFromDate(date?.toDate()!);
              }}
              label={"From Date"}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "10px",
                  height: "45px",
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={2} mt={{ xs: 2, lg: 0 }}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={dayjs(props.toDate)}
              onChange={(date) => {
                props.setToDate(date?.toDate()!);
              }}
              label={"To Date"}
              sx={{
                "& .MuiInputBase-root": {
                  borderRadius: "10px",
                  height: "45px",
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={1.5} mt={{ xs: 2, lg: 0 }}>
        <Select
          fullWidth
          sx={{ p: 0, borderRadius: "10px", height: "45px" }}
          value={props.paymentStatus}
          onChange={(e) => {
            props.setPaymentStatus(e.target.value);
          }}
        >
          {["all", ...Object.keys(paymentStatuses)].map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {props.resellers && props.resellers.length > 0 && (
        <Grid item xs={12} lg={2} mt={{ xs: 2, lg: 0 }}>
          <Select
            fullWidth
            sx={{ p: 0, borderRadius: "10px", height: "45px" }}
            value={props.resellerId || "all sub companies"}
            onChange={(e) => {
              props.setResellerId(Number(e.target.value));
            }}
          >
            {[{ subCompanyName: "all sub companies", id: "all sub companies" }, ...props.resellers].map(
              (item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.subCompanyName}
                </MenuItem>
              )
            )}
          </Select>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="subtitle2" ml={2}>
          Showing {props.paymentStatus} Orders from{" "}
          {props.fromDate.toLocaleDateString("en-GB")} to{" "}
          {props.toDate.toLocaleDateString("en-GB")}
        </Typography>
      </Grid>
    </>
  );
};
