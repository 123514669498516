import {
  CalculateOutlined,
  CheckCircleOutlineRounded,
  LocalShippingOutlined,
  ScheduleRounded,
} from "@mui/icons-material";
import { OrderProductModel } from "./OrderProductModel";
import { colors } from "@mui/material";

export type OrderModel = {
  id: number;
  resellerId: number;
  subtotal: number;
  tax: number;
  deliveryFee: number;
  grandtotal: number;
  contactName: string;
  contactPhone: string;
  paymentMethod: string;
  paymentStatus: string;
  datetime: string;
  billingAddress: string;
  deliveryAddress: string;
  products: OrderProductModel[];
  invoiceLink: string;
  poNumber?: string;
  orderDeliveryDetails: OrderDeliverDetails[];
  totalDiscount: number;
  reseller: {
    subCompanyName: string;
    firstName: string;
    lastName: string;
    companyId: number;
  };
};

type OrderDeliverDetails = {
  contactName: string;
  contactPhone: string;
  deliveryAddress: string;
};

type StatusModel = {
  title: string;
  color: string;
  icon: any;
};

export const deliveryStatuses = {
  processing: "PROCESSING",
  shipped: "SHIPPED",
  delivered: "DELIVERED",
  canceled: "CANCELED",
};

export const paymentStatuses = {
  pending: "PENDING",
  paid: "PAID",
  refunded: "REFUNDED",
};

export const productStatusesMap: Map<string, StatusModel> = new Map();
productStatusesMap.set(deliveryStatuses.processing, {
  title: "Processing",
  color: colors.lightBlue[500],
  icon: ScheduleRounded,
});
productStatusesMap.set(deliveryStatuses.shipped, {
  title: "Shipped",
  color: colors.orange[500],
  icon: LocalShippingOutlined,
});
productStatusesMap.set(deliveryStatuses.delivered, {
  title: "Delivered",
  color: colors.green[500],
  icon: CheckCircleOutlineRounded,
});
productStatusesMap.set(deliveryStatuses.canceled, {
  title: "Canceled",
  color: colors.red[500],
  icon: CalculateOutlined,
});

export const paymentStatusColors: Map<string, string> = new Map();
paymentStatusColors.set(paymentStatuses.pending, colors.orange[500]);
paymentStatusColors.set(paymentStatuses.paid, colors.green[500]);
paymentStatusColors.set(paymentStatuses.refunded, colors.red[500]);
