import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseURL } from "../common/Helper";
import { LocalStorage } from "../common/Localstorage";
import { ResponseModel } from "../models/ResponseModel";
import { CartModel } from "../models/CartModel";
import { CheckoutItemsModel } from "../models/CheckoutItemsModel";
import { PlaceOrderModel } from "../models/PlaceOrderModel";

export const checkoutAPI = createApi({
  reducerPath: "checkoutAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: apiBaseURL,
    prepareHeaders: (headers) => {
      const token = LocalStorage.getToken();
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    requestPaymentData: builder.mutation<ResponseModel, { token: string }>({
      query: (params) => ({
        url: "/payments/request",
        method: "POST",
        body: {
          token: params.token,
        },
      }),
    }),
    getQuotation: builder.mutation<
      ResponseModel,
      { products: CartModel[]; stateId: number; isExpressShipping: boolean }
    >({
      query: (params) => ({
        url: "/orders/quotation",
        method: "POST",
        body: {
          state: params.stateId,
          products: params.products.map((product) => ({
            id: product.productId,
            woocommerceId: product.woocommerceId,
            name: product.name,
            attributes: product.attributes.split(","), // The BE sends string and expect array
            parentId: product.parentId,
            quantity: product.quantity,
            isDigitalGood: product.isDigitalGood,
            isSimpleProduct: product.isSimpleProduct,
            image: product.image,
            ...(product.isRenewal && { isRenewal: product.isRenewal }),
          })),
          isExpressShipping: params.isExpressShipping,
        },
      }),
    }),
    placeOrder: builder.mutation({
      query: (placeOrderModel: PlaceOrderModel) => ({
        url: "/orders/new",
        method: "POST",
        body: placeOrderModel,
      }),
    }),
  }),
});

export const {
  useRequestPaymentDataMutation,
  useGetQuotationMutation,
  usePlaceOrderMutation,
} = checkoutAPI;
