import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CompanyModel } from "../../models/CompanyModel";
import { CompanyFormSchema } from "../../schemas/CompanyFormSchema";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedCountry } from "../../reduxenv/Slices/SelectedCountrySlice";
import { SaveRounded } from "@mui/icons-material";
import {
  getcompanyData,
  setcompanyData,
} from "../../reduxenv/Slices/CompanyDataSlice";
import { AppColors } from "../../common/AppColors";
import { ControlledTextField } from "../shared/ControlledTextField";
import { localStorageKeys } from "../../common/Helper";
import { RegistrationStepIndexes } from "../../common/Enums";
import { getResellerFormData } from "../../reduxenv/Slices/ResellerFormSlice";

interface Props {
  handleGoToNextStep(stepIndex: number): void;
}

const CompanyForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedCountry = useSelector(getSelectedCountry);
  const savedCompanyData = useSelector(getcompanyData);
  const savedResellerData = useSelector(getResellerFormData);
  const [isContactAndBillingSame, setIsContactAndBillingSame] = useState(false);
  const [isContactAndAPSame, setIsContactAndAPSame] = useState(false);
  const [isContactAndBOSame, setIsContactAndBOSame] = useState(false);
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<CompanyModel>({
    resolver: yupResolver(CompanyFormSchema),
    defaultValues: savedCompanyData
      ? savedCompanyData
      : {
          name: "",
          tradingAs: "",
          proprietor: "",
          gstNo: "",
          regNo: "",
          website: "",
          phone: "",
          email: "",
          address: "",
          buildingNumber: "",
          streetName: "",
          city: "",
          state: "",
          postCode: "",
          countryId: 0,
          noOfEmployees: 0,
          yearsInBusiness: 0,
          billingEmail: "",
          billingBuildingNumber: "",
          billingStreetName: "",
          billingPostCode: "",
          billingState: "",
          billingCity: "",
          apName: "",
          apPhone: "",
          appEmail: "",
          appFax: "",
          boName: "",
          boEmail: "",
          boPhone: "",
          boIsPartShip: false,
          expectedMonthlyPurchase: 0,
          creditLimit: 0,
          yearEndDate: "",
          isAudited: false,
        },
  });

  const submit = async (companyData: CompanyModel) => {
    // Setting other values
    companyData.countryId = selectedCountry.id;
    companyData.address =
      companyData.buildingNumber +
      " " +
      companyData.streetName +
      " " +
      companyData.city +
      " " +
      companyData.state;
    companyData.billingAddress =
      companyData.billingBuildingNumber +
      " " +
      companyData.billingStreetName +
      " " +
      companyData.billingCity +
      " " +
      companyData.billingState;
    // -------------------------------------------------------------

    dispatch(setcompanyData(companyData));
    await localStorage.setItem(
      localStorageKeys.company,
      JSON.stringify(companyData)
    );
    props.handleGoToNextStep(RegistrationStepIndexes.Credits);
  };

  function handleCheckSameAddressForBilling(_event: any, checked: boolean) {
    let billingEmail = "";
    let buildingNumber = "";
    let billingStreetName = "";
    let billingPostCode = "";
    let billingCity = "";
    let billingState = "";
    if (checked) {
      let tempCompany: CompanyModel = getValues();
      billingEmail = tempCompany.email;
      buildingNumber = tempCompany.buildingNumber;
      billingStreetName = tempCompany.streetName;
      billingPostCode = tempCompany.postCode;
      billingCity = tempCompany.city;
      billingState = tempCompany.state;
    }
    setValue("billingEmail", billingEmail);
    setValue("billingBuildingNumber", buildingNumber);
    setValue("billingStreetName", billingStreetName);
    setValue("billingPostCode", billingPostCode);
    setValue("billingCity", billingCity);
    setValue("billingState", billingState);
    setIsContactAndBillingSame(checked);
  }

  function handleCheckSameAddressForAPorBO(
    _event: any,
    checked: boolean,
    isBookingOfficer = false
  ) {
    let name = "";
    let phone = "";
    let email = "";
    if (checked) {
      name = savedResellerData.firstName + " " + savedResellerData.lastName;
      email = savedResellerData.email;
      phone = savedResellerData.phone;
    }
    if (isBookingOfficer) {
      setValue("boEmail", email);
      setValue("boName", name);
      setValue("boPhone", phone);
      setIsContactAndBOSame(checked);
    } else {
      setValue("appEmail", email);
      setValue("apName", name);
      setValue("apPhone", phone);
      setIsContactAndAPSame(checked);
    }
  }

  return (
    <Card
      sx={{ p: 2 }}
      variant="outlined"
      component={"form"}
      onSubmit={handleSubmit(submit)}
    >
      <CardContent>
        <Typography variant="h4" color={AppColors.Blue}>
          Basic Details
        </Typography>
        <Divider />
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="name"
              label="Company Name*"
              error={errors.name}
              inputProps={{
                autoFocus: !savedCompanyData,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="tradingAs"
              label="Trading As"
              error={errors.tradingAs}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="proprietor"
              label="Proprietor / Partnership / Trust Name"
              error={errors.proprietor}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="gstNo"
              label={
                selectedCountry &&
                (selectedCountry.taxNoTerm !== ""
                  ? selectedCountry.taxNoTerm
                  : "TAX Number") + "*"
              }
              error={errors.gstNo}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="regNo"
              label={selectedCountry.regNoTerm + "*"}
              error={errors.regNo}
            />
          </Grid>          
          <Divider />
          <Typography mt={2} variant="h4" color={AppColors.Blue}>
            Contact Details
          </Typography>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="website"
              label={"Website"}
              error={errors.website}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="phone"
              label={"Phone*"}
              error={errors.phone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="email"
              label={"Email Address*"}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"space-between"} spacing={2}>
              <Grid item lg={3} xs={12}>
                <ControlledTextField
                  control={control}
                  name="buildingNumber"
                  label={"Building Number*"}
                  error={errors.buildingNumber}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <ControlledTextField
                  control={control}
                  name="streetName"
                  label={"Street Name*"}
                  error={errors.streetName}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <ControlledTextField
                  control={control}
                  name="city"
                  label={"City*"}
                  error={errors.city}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item lg={8} xs={12} ml={2}>
              <ControlledTextField
                control={control}
                name="state"
                label={"State*"}
                error={errors.state}
              />
            </Grid>
            <Grid
              item
              lg={3}
              xs={12}
              ml={{ lg: 5, xs: 2 }}
              mt={{ xs: 2, sm: 0 }}
            >
              <ControlledTextField
                control={control}
                name="postCode"
                label={"Post Code*"}
                error={errors.postCode}
              />
            </Grid>
          </Grid>
          <Grid item lg={8}>
            <Typography mt={2} variant="h4" color={AppColors.Blue}>
              Billing Details
            </Typography>
          </Grid>
          <Grid item lg={4} className="center-content">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isContactAndBillingSame}
                  onChange={handleCheckSameAddressForBilling}
                />
              }
              label={"Same as above"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="billingEmail"
              label={"Invoice Email Address*"}
              error={errors.billingEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={"space-between"} spacing={2}>
              <Grid item lg={3} xs={12}>
                <ControlledTextField
                  control={control}
                  name="billingBuildingNumber"
                  label={"Building Number*"}
                  error={errors.billingBuildingNumber}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <ControlledTextField
                  control={control}
                  name="billingStreetName"
                  label={"Street Name*"}
                  error={errors.billingStreetName}
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <ControlledTextField
                  control={control}
                  name="billingCity"
                  label={"City*"}
                  error={errors.billingCity}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item lg={8} xs={12} ml={2}>
              <ControlledTextField
                control={control}
                name="billingState"
                label={"State*"}
                error={errors.billingState}
              />
            </Grid>
            <Grid
              item
              lg={3}
              xs={12}
              ml={{ lg: 5, xs: 2 }}
              mt={{ xs: 2, sm: 0 }}
            >
              <ControlledTextField
                control={control}
                name="billingPostCode"
                label={"Post Code*"}
                error={errors.billingPostCode}
              />
            </Grid>
          </Grid>
          <Grid item lg={8}>
            <Typography mt={2} variant="h4" color={AppColors.Blue}>
              Account Payable Details
            </Typography>
          </Grid>
          <Grid item lg={4} className="center-content">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isContactAndAPSame}
                  onChange={(e, checked) => {
                    handleCheckSameAddressForAPorBO(e, checked);
                  }}
                />
              }
              label={"Same as reseller details"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="apName"
              label={"Name*"}
              error={errors.apName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="apPhone"
              label={"Phone*"}
              error={errors.apPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="appEmail"
              label={"Email"}
              error={errors.appEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="appFax"
              label={"Fax"}
              error={errors.appFax}
            />
          </Grid>
          <Grid item lg={8}>
            <Typography mt={2} variant="h4" color={AppColors.Blue}>
              Booking Officer Details
            </Typography>
          </Grid>
          <Grid item lg={4} className="center-content">
            <FormControlLabel
              control={
                <Checkbox
                  checked={isContactAndBOSame}
                  onChange={(e, checked) => {
                    handleCheckSameAddressForAPorBO(e, checked, true);
                  }}
                />
              }
              label={"Same as reseller details"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="boName"
              label={"Name"}
              error={errors.boName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="boPhone"
              label={"Phone"}
              error={errors.boPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="boEmail"
              label={"Email Address"}
              error={errors.boEmail}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="boIsPartShip"
                  render={({ field }) => (
                    <Checkbox {...field} checked={field.value} />
                  )}
                />
              }
              label="Part Ship as Default Shipment"
            />
          </Grid>
          <Typography mt={2} variant="h4" color={AppColors.Blue}>
            Other Details
          </Typography>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="noOfEmployees"
              label={"No. of employees"}
              error={errors.noOfEmployees}
              inputProps={{ type: "number" }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              name="yearsInBusiness"
              label={"Years in business"}
              error={errors.yearsInBusiness}
              inputProps={{ type: "number" }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          color="success"
          sx={{ mt: 2, pt: 1, pl: 4, pr: 4, ml: "auto", display: "flex" }}
          variant="contained"
          endIcon={<SaveRounded />}
        >
          SAVE
        </Button>
      </CardContent>
    </Card>
  );
};

export default CompanyForm;
