import { DeleteRounded } from "@mui/icons-material";
import { Grid, Box, Avatar, Typography, IconButton } from "@mui/material";
import React, { useState } from "react";
import { QuantityButton } from "../shared/QuantityButton";
import { CartModel } from "../../models/CartModel";
import { Images } from "../../common/Images";

interface Props {
  styles: any;
  product: CartModel;
  currency: string;
  updateProduct(product: CartModel): void;
  removeProduct(id: number): void;
}

export const CartItem: React.FC<Props> = (props) => {
  let styles = props.styles;
  let product = props.product;
  const [quantity, setQuantity] = useState(product.quantity);

  function handleChangeQuantity(newQuantity: number) {
    let tempProduct = { ...product };
    tempProduct.quantity = newQuantity;
    props.updateProduct(tempProduct);
    setQuantity(newQuantity);
  }

  return (
    <Grid item xs={12}>
      <Box sx={styles.cartItem}>
        <Avatar sx={styles.image} variant="square" src={product.image ?? Images.placeholder} />
        <div style={styles.content}>
          <div style={styles.contentInner}>
            <div style={styles.wrapper}>
              <Typography sx={styles.productName}>{product.name}</Typography>
              <IconButton
                sx={styles.deleteButton}
                onClick={() => {
                  props.removeProduct(product.productId);
                }}
              >
                <DeleteRounded />
              </IconButton>
            </div>
            <Typography variant="subtitle1" sx={styles.subtitle}>
              {product.attributes}
            </Typography>
            <div style={styles.wrapper}>
              <Typography sx={styles.price}>
                {(product.price * product.quantity).toFixed(2)} {props.currency}
              </Typography>
              <div style={styles.quantity}>
                <QuantityButton
                  quantity={quantity}
                  setQuantity={handleChangeQuantity}
                  max={product.availability}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Grid>
  );
};
