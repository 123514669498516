import {
  ArrowRight,
  ShoppingBagOutlined,
  ShoppingCart,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isShowCart, setShowCart } from "../../reduxenv/Slices/CartSlice";
import { StyleContext } from "../../context/StyleContextProvider";
import { getProfile } from "../../reduxenv/Slices/ProfileSlice";
import { CartItem } from "./CartItem";
import { useNavigate } from "react-router-dom";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import {
  useDeleteCartItemMutation,
  useGetAllCartItemsQuery,
  useUpdateCartMutation,
} from "../../services/CartServices";
import { CartModel } from "../../models/CartModel";
import { snakeToCamel } from "../../common/Helper";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";

export const CartDialog: React.FC = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<CartModel[]>([]);
  const showCart = useSelector(isShowCart);
  const dispatch = useDispatch();
  const styleContext = useContext(StyleContext);
  const [styles, setStyles] = useState(styleContext.getComponentStyle("cart"));
  const isMobile = useMediaQuery("(max-width: 786px)");
  const profile = useSelector(getProfile);
  const { formatCurrency } = useFormatCurrency();
  const [totalPrice, setTotalPrice] = useState<number>();
  const { data: cartItemsResponse, refetch: getCartItems } =
    useGetAllCartItemsQuery();
  const [updateCartItem] = useUpdateCartMutation();
  const [deleteCartItem] = useDeleteCartItemMutation();

  useEffect(() => {
    if (showCart) {
      getCartItems();
    }
  }, [showCart]);

  useEffect(() => {
    if (cartItemsResponse && cartItemsResponse.status === "success") {
      let tempItems = snakeToCamel(cartItemsResponse.response) as CartModel[];
      setCartItems(tempItems);
    }
  }, [cartItemsResponse]);

  useEffect(() => {
    setStyles(styleContext.getComponentStyle("cart"));
  }, [isMobile]);

  useEffect(() => {
    let tempTotal = 0;
    cartItems.forEach((product) => {
      tempTotal += product.price * product.quantity;
    });
    setTotalPrice(tempTotal);
  }, [cartItems]);

  function toggleShowCart() {
    dispatch(setShowCart(!showCart));
  }

  function removeProductFromCart(productId: number) {
    dispatch(setIsLoading(true));
    deleteCartItem(productId)
      .then(() => {
        getCartItems();
        dispatch(setIsLoading(false));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
      });
  }

  function handleClickCheckout() {
    navigate("/checkout", { state: cartItems });
    dispatch(setShowCart(false));
  }

  function handleChangeQuantity(product: CartModel) {
    dispatch(setIsLoading(true));
    updateCartItem({
      productId: product.productId,
      quantity: product.quantity,
    })
      .then(() => {
        getCartItems();
        dispatch(setIsLoading(false));
      })
      .catch(() => {
        dispatch(setIsLoading(false));
      });
  }

  return (
    <>
      <Button
        onClick={toggleShowCart}
        sx={styles.navButton}
        endIcon={
          <Icon>
            <ShoppingBagOutlined fontSize="small" />
          </Icon>
        }
      >
        Cart
      </Button>
      <Dialog fullWidth open={showCart} onClose={toggleShowCart} scroll="paper">
        <DialogTitle>My Cart</DialogTitle>
        <DialogContent>
          <Grid container>
            {cartItems && cartItems.length > 0 ? (
              <>
                {cartItems.map((product, index) => (
                  <CartItem
                    styles={styles}
                    product={product}
                    key={index}
                    currency={profile.country.currency}
                    updateProduct={handleChangeQuantity}
                    removeProduct={removeProductFromCart}
                  />
                ))}
                <Box sx={styles.totalBox}>
                  <Typography variant="h4" sx={styles.totalPrice}>
                    {formatCurrency(totalPrice)}
                    <span style={styles.gst}>
                      {profile.country.isTaxIncluded
                        ? "Including GST "
                        : "Excluding GST "}
                      {profile.country.taxPercentage}%
                    </span>
                  </Typography>
                  <Button
                    sx={styles.checkoutButton}
                    variant="contained"
                    fullWidth
                    endIcon={
                      <Icon>
                        <ArrowRight />
                      </Icon>
                    }
                    onClick={handleClickCheckout}
                  >
                    Checkout
                  </Button>
                </Box>
              </>
            ) : (
              <Grid item xs={12}>
                <Box sx={styles.cartEmpty}>
                  <Typography variant="button">
                    <Icon>
                      <ShoppingCart />
                    </Icon>
                    Your cart is empty!
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
