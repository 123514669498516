import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { Control, FormState } from "react-hook-form";
import { ControlledTextField } from "../shared/ControlledTextField";
import { StatesSelect } from "../shared/StatesSelect";
import { StateModel } from "../../models/StateModel";
import { CheckoutAddressModel } from "../../models/CheckoutAddressModel";
import { AppColors } from "../../common/AppColors";
import { ProfileModel } from "../../models/ProfileModel";

interface Props {
  control: Control<CheckoutAddressModel, any>;
  profile: ProfileModel;
  isBillingAndDeliverySame: boolean;
  handleSelectState(state: StateModel): void;
  selectedStateId?: number;
  setSameAsBilling(_event: any, checked: boolean): void;
  errors: FormState<CheckoutAddressModel>;
  isExpressShipping: boolean;
  setExpressShipping(_event: any, checked: boolean): void;
  isDeliveryApplicable: boolean;
}

export const DeliveryAndBillingForm: React.FC<Props> = (props) => {
  const profile = props.profile;
  const control = props.control;
  const errors = props.errors.errors;

  return (
    <Card variant="outlined">
      <CardContent component={"form"}>
        <Grid container spacing={2}>
          <Grid item lg={7}>
            <Typography mt={2} variant="h4" color={AppColors.Blue}>
              Billing Details
            </Typography>
          </Grid>
          {props.isDeliveryApplicable && (
            <Grid item lg={5} className="center-content">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.isExpressShipping}
                    onChange={props.setExpressShipping}
                  />
                }
                label={"Express Shipping"}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="First Name*"
              name="billingFirstName"
              error={errors.billingFirstName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Last Name*"
              name="billingLastName"
              error={errors.billingLastName}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Phone*"
              name="billingPhone"
              error={errors.billingPhone}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Billing Address Line 1*"
              name="billingAddress1"
              error={errors.billingAddress1}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              control={control}
              label="Billing Address Line 2*"
              name="billingAddress2"
              error={errors.billingAddress2}
            />
          </Grid>
          {props.isBillingAndDeliverySame && (
            <Grid item xs={12}>
              <StatesSelect
                selectedStateId={props.selectedStateId!}
                getSelectedState={props.handleSelectState}
                countryId={profile.country.id}
                error={errors.stateName}
              />
            </Grid>
          )}
          <Grid item lg={7}>
            <Typography mt={2} variant="h4" color={AppColors.Blue}>
              Delivery Details
            </Typography>
          </Grid>
          <Grid item lg={5} className="center-content">
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.isBillingAndDeliverySame}
                  onChange={props.setSameAsBilling}
                />
              }
              label={"Same as billing details"}
            />
          </Grid>
          {!props.isBillingAndDeliverySame && (
            <>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label="First Name*"
                  name="deliveryFirstName"
                  error={errors.deliveryFirstName}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label="Last Name*"
                  name="deliveryLastName"
                  error={errors.deliveryLastName}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  label="Phone*"
                  name="deliveryPhone"
                  error={errors.deliveryPhone}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="deliveryAddress1"
                  label={"Delivery Address Line 1*"}
                  error={errors.deliveryAddress1}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledTextField
                  control={control}
                  name="deliveryAddress2"
                  label={"Delivery Address Line 2*"}
                  error={errors.deliveryAddress2}
                />
              </Grid>
              <Grid item xs={12}>
                <StatesSelect
                  selectedStateId={props.selectedStateId!}
                  getSelectedState={props.handleSelectState}
                  countryId={profile.country.id}
                  error={errors.stateName}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
